export default [
  {
    header: 'configuraciones',
    resource: 'config',
    action: 'read',
  },
  {
    title: 'configuracion',
    icon: 'ToolIcon',
    children: [
      {
        title: 'general',
        children: [
          {
            title: 'opciones',
            route: null,
            resource: 'config',
            action: 'read',
          },
          {
            title: 'genero',
            route: 'type-gender',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_documentos',
            route: 'type-documents',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_financiacion',
            route: 'type-financing',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_estado_civil',
            route: 'type-stado-civil',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_estudios',
            route: 'type-studio',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_grado_academico',
            route: 'type-grado-academico',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'fecha_viaje',
            route: 'fecha-viaje',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_cargo',
            route: 'type-cargo',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'documentos',
            route: 'documents-type',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_cliente',
            route: 'type-client',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'estados',
            route: 'type-status',
            resource: 'config',
            action: 'read',
          },
        ],
      },
      {
        title: 'SMS',
        route: null,
        resource: 'config',
        action: 'read',
      },
      {
        title: 'paises_ciudades',
        route: 'config-country-city',
        resource: 'config',
        action: 'read',
      },
      {
        title: 'universidades',
        route: 'config-universidad',
        resource: 'config',
        action: 'read',
      },
      {
        title: 'regalos_obsequios',
        route: 'type-regalo',
        resource: 'config',
        action: 'read',
      },
      {
        title: 'roles_permisos',
        route: 'roles-permisos',
        resource: 'config',
        action: 'read',
      },
      {
        title: 'contrato_config',
        children: [
          {
            title: 'contrato_version',
            route: 'config-contract-version',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'tipo_contratos',
            route: 'type-contract',
            resource: 'config',
            action: 'read',
          },
          {
            title: 'contrato',
            route: 'config-contract',
            resource: 'config',
            action: 'read',
          },
        ],
      },
    ],
  },
]
