export default [
  {
    title: 'curso',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'ruso',
        route: 'curso-ruso',
        resource: 'cursos',
        action: 'read',
      },
      {
        title: 'checo',
        route: 'curso-checo',
        resource: 'cursos',
        action: 'read',
      },
      {
        title: 'ingles',
        route: 'curso-ingles',
        resource: 'cursos',
        action: 'read',
      },
    ],
  },
]
