export default [
  {
    title: 'users',
    icon: 'UsersIcon',
    resource: 'users',
    action: 'read',
    children: [
      {
        title: 'estudiante',
        route: 'users-list',
        resource: 'users',
        action: 'read',
      },
      {
        title: 'all_users',
        route: 'users-list-all',
        resource: 'users',
        action: 'read',
      },
    ],
  },
]
