export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    // tag: '1',
    // tagVariant: 'light-warning',
    resource: 'dashboard',
    action: 'read',
    children: [
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
        resource: 'dashboard',
        action: 'read',
      },
    ],
  },
]
