export default [
  {
    title: 'profile_data_show',
    icon: 'UserIcon',
    resource: 'profile',
    action: 'read',
    children: [
      {
        title: 'profile_show',
        icon: 'UserIcon',
        route: 'apps-users-view',
        resource: 'profile',
        action: 'read',
      },
      {
        title: 'profile_data_edit',
        icon: 'UserIcon',
        route: 'users-profile',
        resource: 'profile',
        action: 'read',
      },
      {
        title: 'viaje_edit',
        icon: 'NavigationIcon',
        route: 'users-viaje',
        resource: 'profile',
        action: 'read',
      },
      {
        title: 'contract_edit',
        icon: 'DollarSignIcon',
        route: 'users-contract',
        resource: 'contrato',
        action: 'read',
      },
      {
        title: 'documentos',
        icon: 'PaperclipIcon',
        resource: 'documentosestudiante',
        action: 'read',
        children: [
          {
            title: 'all_documents',
            route: 'documents-list',
            resource: 'documentosestudiante',
            action: 'read',
          },
        ],
      },
      {
        title: 'family',
        icon: 'UserPlusIcon',
        resource: 'familiares',
        action: 'read',
        children: [
          {
            title: 'all_familiares',
            route: 'family-list',
            resource: 'familiares',
            action: 'read',
          },
        ],
      },
    ],
  },
]
